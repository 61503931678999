<template>
    <v-container>
        <v-row class="mx-auto" justify="center">
            <v-col cols="12">
                <v-card-title class="my-5">
                    <v-row class="mx-auto flex flex-col" justify="center">
                        <!-- 
                            <v-avatar size="200" class="mx-auto my-4">
                            <v-img
                            v-if="user.image !== null"
                            :src="previewUrl !== null ? previewUrl : user.image_url"
                            />
                            <v-img v-else
                            :src="previewUrl !== null ? previewUrl : null"/>
                        </v-avatar>
                            <v-card-subtitle v-if="imageError" class="text-center text-red-500">{{imageErrorMessage}}</v-card-subtitle>
                        <v-btn
                            color="error"
                            class="text-none mx-auto"
                            rounded
                            depressed
                            @click="$refs.fileInput.click()"
                        >
                            <v-icon left>
                            mdi-cloud-upload
                            </v-icon>
                            upload
                        </v-btn>
                        <input type="file" ref="fileInput" class="hidden" @change="fileInputChange" /> 
                    -->

                    </v-row>
                </v-card-title>

                <v-form class="p-5">
                    <v-text-field
                    label="Firstname"
                    name="Firstname"
                    prepend-icon="mdi-account"
                    type="text"
                    color="blue accent-3"
                    class="px-4"
                    :error-messages="errors.firstname"
                    v-model="form.firstname"
                    />

                    <v-text-field
                    label="Lastname"
                    name="Lastname"
                    prepend-icon="mdi-account"
                    type="text"
                    color="blue accent-3"
                    class="px-4"
                    :error-messages="errors.lastname"
                    v-model="form.lastname"
                    />

                    <v-text-field
                    label="Email"
                    name="Email"
                    readonly
                    prepend-icon="mdi-email"
                    type="email"
                    color="blue accent-3"
                    class="px-4"
                    :error-messages="errors.email"
                    v-model="form.email"
                    />

                    <v-select :items="countries" label="Country" prepend-icon="mdi-pin" color="blue accent-3" class="px-4" required
                        :error-messages="errors.country" v-model="form.country" item-text="name" item-value="name" return-object />

                </v-form>

                <v-row justify="center" class="p-5 flex flex-row">
                    <v-col cols="3" class="mx-auto">
                        <!-- <v-btn class="mx-auto mb-4" :loading="loading" block rounded dark color="red" @click="updateUser">Save</v-btn> -->
                        <v-btn class="mx-auto mb-4" :loading="loading" block rounded dark color="red" >Save</v-btn>
                    </v-col>
                </v-row>

            </v-col>

            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                :color="snackBarColor"
            >
                {{ text }}

                <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
                </template>
            </v-snackbar>

        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import countriesJson  from '@/utils/countries.json' 
export default {

    data() {
        return {
            errors: {},
            imageError: false,
            imageErrorMessage: 'There was a problem with the image',
            previewUrl: null,
            defaultUrl: require('../../assets/1.jpg'),
            countries: countriesJson,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                image: null,
                country: null
            },
            loading: false,

            snackbar: false,
            text: '',
            snackBarColor: 'error',
            timeout: 5000,
        }
    },

    computed: {
        user() {
            // return {
            //     firstname: 'Amin',
            //     lastname: 'Hussein',
            //     email: 'amin@emai.com',
            // }
            return this.$session.get('user')
        }
    },

    mounted() {
        this.setUser()
    },

    methods: {
        setUser() {
            this.form.firstname = this.user.firstname
            this.form.lastname = this.user.lastname
            this.form.email = this.user.email
            // get country object from countries array
            this.form.country = this.countries.find(country => country.name === this.user.country)
        },
        resetUser() {
            this.form.firstname = ''
            this.form.lastname = ''
            this.form.email = ''
            this.form.image = null
            this.form.country = null
        },
        fileInputChange(e) {
            const file = e.target.files
            if (file.length !== 0) {
                this.previewUrl = URL.createObjectURL(file[0])
                if(file[0].size > 2000000) {
                    this.imageError = true
                    this.imageErrorMessage = 'File must be less than 2MB'
                    this.previewUrl = null
                } else {
                    this.imageError = false
                    this.form.image = file[0]
                    // console.log(file[0]);
                }
            } else {
                this.previewUrl = null
            }
        },

        async updateUser() {
            try {
                this.loading = true
                
                let formData = {
                    id: this.user.id,
                    first_name: this.form.firstname,
                    last_name: this.form.lastname,
                    country: this.form.country.name,
                    country_code: this.form.country.code
                }

                await axios.put(process.env.VUE_APP_API_URL +'/user/edit', formData)

                //update user session
                // replace user details in session with new user details

                axios
                .get(process.env.VUE_APP_API_URL + '/refreshToken', {
                    'Authorization': 'Bearer ' + this.$session.get('refresh_token')
                })
                .then((response) => {

                    // save the new token
                    this.$session.set('access_token', response.data.access_token)
                    this.$session.set('refresh_token', response.data.refresh_token)
                    this.$session.remove('user')
                    this.$session.set('user', response.data.user)
                })

                this.loading = false
                this.showSnackBar('success', 'Updated successfully')
                this.$router.go(0)
                
            } catch (error) {
                this.loading = false
                this.showSnackBar('error', error.response.data.error)
            }
        },

        showSnackBar(type, message){
            switch (type) {
                case 'success':
                    this.snackbar = true
                    this.text = message
                    this.timeout = 3000
                    this.snackBarColor = 'success'
                    break;
                case 'error':
                    this.snackbar = true
                    this.text = message
                    this.timeout = 20000
                    this.snackBarColor = 'error'
                    break;
            
                default:
                    break;
            }
        }
    }

}
</script>

<style>

</style>