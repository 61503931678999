<template>
    <v-row class="h-full">
        <v-col cols="12" md="4">
            <v-img src="@/assets/push.svg" height="100%" width="100%" contain :aspect-ratio="16 / 9"></v-img>
        </v-col>

        <v-col cols="12" md="8">
            <v-card-title class="my-5">
                <v-row class="mx-auto" justify="center">
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="6">
                                <v-btn text color="primary">Login</v-btn>
                            </v-col>
                            <v-col cols="6"><v-btn text @click="incrementStep">Register</v-btn></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-form class="p-5" ref="form" v-model="valid">
                <v-text-field :rules="[() => !!form.email || 'This field is required']" label="Email" name="Email"
                    prepend-icon="mdi-email" type="email" color="blue accent-3" class="px-4" v-model="form.email" />

                <v-text-field :rules="[() => !!form.password || 'This field is required']" id="password" label="Password"
                    name="password" prepend-icon="mdi-lock" :type="showPassword ? 'text' : 'password'" color="blue accent-3"
                    class="px-4" v-model="form.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword" />
            </v-form>

            <v-row justify="center" class="p-5 flex flex-col">
                <v-col cols="3" class="mx-auto">
                    <v-btn class="mx-auto mb-4" :loading="loading" block rounded dark color="red"
                        @click="submitForm">Login</v-btn>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row justify="center" class="p-3">
                    <a href="/forgot-password" class="text-center text-sm ">Forgot your password ?</a>
                </v-row>
            </v-card-actions>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { Messages } from '@/utils/constants';

export default {
    name: 'LoginForm',
    data() {
        return {
            valid: true,
            loading: false,
            showPassword: false,
            form: {
                'email': 'test@email.com',
                'password': 'test',
            },
        }
    },

    computed: {
        ...mapGetters({
            errors: 'getLoginFormErrors',
        }),
    },

    methods: {
        incrementStep() {
            this.$emit('incrementStep')
        },

        async submitForm() {
            this.loading = true

            await this.validate()
            if (!this.valid) {
                this.loading = false
                return
            }

            // temp change below
            if (this.form.email == 'test@email.com' && this.form.password == 'test') {
                this.loading = false

                let data = {
                    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0cnVibGVAZW1haWwuY29tIiwicm9sZXMiOlsiQUNUSVZFIiwiTElOS0VEIiwiUk9MRV9VU0VSIiwiVkVSSUZJRUQiXSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgxL2FwaS9sb2dpbiIsImV4cCI6MTcwODM2OTQ4N30.Q_1mlMwPoQ8-rwL09f1jDaH9z7Y1UL33-DXMBzdZaW0",
                    "refresh_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0cnVibGVAZW1haWwuY29tIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgxL2FwaS9sb2dpbiIsImV4cCI6MTcwODU0MjI4N30.dafsKDunRLKQlI8QL5iFl3_-vCOHVvLcqV3qxPW5bx8",
                    "user": {
                        "id": 13,
                        "email": "test@email.com",
                        "address": "Allien Avenue, Mars",
                        "image": null,
                        "firstname": "John",
                        "lastname": "Doe",
                        "fullName": "John Doe",
                        "emailVerified": true,
                        "cardLinked": true,
                        "country": "United States",
                        "countryCode": "US",
                        "status": true
                    }
                }

                let userDetails = {
                    user: data.user,
                    access_token: data.access_token,
                    refresh_token: data.refresh_token
                }
                this.$store.commit('SET_USER', userDetails)

                await this.$store.dispatch('resetLoginErrors')
                //then redirect user
                await this.$router.push({ path: '/' })
            } else {
                this.loading = false
                this.$store.dispatch('showSnackBar', { status: true, message: 'Authentication failed', type: Messages.ERROR })
            }

            // const formData = new FormData();
            // formData.append("email", this.form.email)
            // formData.append("password", this.form.password)
            // formData.append('_method', 'POST')

            // try {
            //     //if there is no session, attempt login
            //     if (!this.$session.has('access_token')) {
            //         await this.$store.dispatch('login', formData)
            //     }

            //     this.loading = false

            //     if (this.$session.has('access_token')) {
            //         //first clear error messaegs from state
            //         await this.$store.dispatch('resetLoginErrors')
            //         //then redirect user
            //         await this.$router.push({ path: '/' })
            //     }

            // } catch (error) {
            //     // if error is from server, show error message
            //     if (error.response) {
            //         this.loading = false
            //         this.$store.dispatch('showSnackBar', { status: true, message: 'Authentication failed', type: Messages.ERROR })
            //     }
            // }
        },
        async validate() {
            const { valid } = await this.$refs.form.validate()
            return valid
        },
    }
}
</script>

<style></style>