<template>
    <v-row class="h-full">
        <v-col cols="12" md="4">
            <v-img src="@/assets/confirm.svg" height="100%" width="100%" contain :aspect-ratio="16 / 9"></v-img>
        </v-col>

        <v-col cols="12" md="8">
            <v-card-title class="my-5">
                <v-row class="mx-auto" justify="center">
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="6">
                                <v-btn text @click="decrementStep">Login</v-btn>
                            </v-col>
                            <v-col cols="6"><v-btn text color="primary">Register</v-btn></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text class="mt-12">
                <v-form>
                    <v-text-field label="Firstname" name="Firstname" prepend-icon="mdi-account" type="text"
                        color="blue accent-3" class="px-4" required v-model="form.firstname" />

                    <v-text-field label="Lastname" name="Lastname" prepend-icon="mdi-account" type="text"
                        color="blue accent-3" class="px-4" required v-model="form.lastname" />

                    <v-text-field label="Email" name="Email" prepend-icon="mdi-email" type="text" color="blue accent-3"
                        class="px-4" required v-model="form.email" />

                    <v-select :items="countries" label="Country" prepend-icon="mdi-pin" color="blue accent-3" class="px-4"
                        required v-model="form.country" item-text="name" item-value="name" return-object />

                    <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password"
                        color="blue accent-3" class="px-4" required v-model="form.password" />

                    <v-text-field id="password-confirm" label="Confirm password" name="password_confirm"
                        prepend-icon="mdi-lock" type="password" color="blue accent-3" class="px-4" required
                        v-model="form.password_confirm" />

                    <div class="flex flex-col px-5 py-4 border-t border-slate-200">
                        <small class="text-red-500" v-for="(error, i) in errors" :key="i">* {{ error }}</small>
                    </div>

                </v-form>
            </v-card-text>
            <div class="text-center mt-n5">
                <v-col cols="3" class="mx-auto">
                    <!-- <v-btn disabled class="mx-auto mb-4 uppercase" :loading="loading" block rounded dark color="red"
                        @click="submitForm">Sign up</v-btn> -->
                    <v-btn disabled class="mx-auto mb-4 uppercase" :loading="loading" block rounded dark color="red"
                    >Sign up</v-btn>
                </v-col>
            </div>
        </v-col>

        <v-col cols="11" class="mx-auto my-5">
            <v-alert v-model="alert" type="success" dismissible>Success. Please verify your email and then log
                in</v-alert>
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import countriesJson from '@/utils/countries.json'
import { Messages } from '@/utils/constants';

export default {
    name: 'RegisterForm',
    data() {
        return {
            loading: false,
            alert: false,
            countries: countriesJson,
            form: {
                'firstname': '',
                'lastname': '',
                'email': '',
                'country': '',
                'country_code': '',
                'address': '',
                'password': '',
                'password_confirm': '',
            },
            password_confirm_error: ''
        }
    },

    computed: {
        ...mapGetters({
            errors: 'getRegisterFormErrors',
            resp: 'getRgisterFormResponse'
        }),
    },

    methods: {
        decrementStep() {
            this.$emit('decrementStep')
        },

        async submitForm() {
            this.loading = true
            //validate
            if (!this.checkIfPasswordsMatch()) {
                this.password_confirm_error = 'Passwords do not match'
            } else {
                this.password_confirm_error = ''
            }

            let formData = {
                first_name: this.form.firstname,
                last_name: this.form.lastname,
                email: this.form.email,
                password: this.form.password,
                country: this.form.country.name,
                country_code: this.form.country.code,
                address: this.form.address
            }

            try {
                //if there is no session, attempt login
                if (!this.$session.has('access_token')) {
                    await this.$store.dispatch('register', formData)
                }

                this.loading = false

                //log in the user
                // if(this.$session.has('token')){
                if (this.$store.getters.getUser !== null) {
                    //first clear error messaegs from state
                    await this.$store.dispatch('resetRegisterErrors')
                    //then redirect user
                    // await this.$router.push({ name: 'Home' })

                    //reset form inputs
                    this.resetFormFields()
                    this.alert = true
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', { status: true, message: error.response.errors, type: Messages.ERROR })
            }
        },

        checkIfPasswordsMatch() {
            if (this.form.password === this.form.password_confirm) {
                return true
            }
            return false
        },

        resetFormFields() {
            this.form.firstname = ''
            this.form.lastname = ''
            this.form.email = ''
            this.form.country = ''
            this.form.country_code = ''
            this.form.address = ''
            this.form.password = ''
            this.form.password_confirm = ''
            this.form.password_confirm_error = ''
        }
    }
}
</script>

<style></style>